import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "gatsby-theme-stack/src/integration-layout";
export const _frontmatter = {
  "title": "Salesforce",
  "author": "admin",
  "date": "2019-01-01T00:00:00.000Z",
  "image": "img/salesforce.svg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Salesforce`}</h1>
    <p>{`Automatically send your leads to Salesforce`}</p>
    <hr></hr>
    <p>{`Salesforce is the most popular CRM solution and if your team is it, here is your solution. This integration automatically sends contact added on Crisp to Salesforce so you don't have to manually sync users`}</p>
    <p>{`Some benefits of using this integration:`}</p>
    <ul>
      <li parentName="ul">{`Auto syncs contacts added in Crisp on Salesforce`}</li>
      <li parentName="ul">{`Your sales are now able to retarget your Crisp leads using their favorite tool`}</li>
    </ul>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      